(function ($) {
  Drupal.behaviors.basicGridFormatterV1 = {
    attach: function (context, settings) {
      var $templates = $('.js-basic-grid-formatter', context);

      $templates.each(function () {
        var $template = $(this);
        var $lazies = $('img.lazyload', $template);

        if ($lazies.length) {
          $lazies.last().on('load', function () {
            // $template.trigger('grid.reflow');   // doesn't work
            $('.js-grid').trigger('grid.reflow');
          });
        }
      });
    }
  };
})(jQuery);
